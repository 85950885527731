<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="SALARY_INFO"
      tooltip-title="SALARY_INFO"
      :display-breadcrumb="true"
    />
    <div
      class="min-h-screen bg-bg-color-white pt-7 mt-7 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <div class="flex lg:gap-3 flex-col lg:flex-row">
        <InputBox
          type="number"
          name="Salary"
          title="Salary"
          label="Salary"
          placeholder="$4500"
          class="flex-1"
        />
        <InputBox
          type="number"
          name="Allowance"
          title="Allowance"
          label="Allowance"
          placeholder="$500"
          class="flex-1"
        />
      </div>
      <div class="flex lg:gap-3 flex-col lg:flex-row">
        <InputBox
          type="text"
          name="Bank Account No"
          title="Bank Account No"
          label="Bank Account No"
          placeholder="001 234 654 6555"
          class="flex-1"
        />
        <InputBox
          type="text"
          name="Bank Name"
          title="Bank Name"
          label="Bank Name"
          placeholder="Standard Chartered Bank"
          class="flex-1"
        />
      </div>
      <div class="flex lg:gap-3 flex-col lg:flex-row">
        <InputBox
          type="text"
          name="Branch Name"
          title="Branch Name"
          label="Branch Name"
          placeholder="Dhaka"
          class="flex-1"
        />
        <InputBox
          type="text"
          name="Swift Code"
          title="Swift Code"
          label="Swift Code"
          placeholder="SCBLUS33"
          class="flex-1"
        />
      </div>
      <InputBox
        type="text"
        name="IBAN"
        title="IBAN"
        label="IBAN"
        placeholder="AT483200000012345864"
        class="flex-1"
      />
      <button
        class="mt-5 focus:outline-none focus:shadow-md ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
      >
        Save Changes
      </button>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    TitleWrapper,
    InputBox,
  },
  mounted() {
    this.setRightbarData()
  },
  methods: {
    ...mapActions('layout', ['setRightbarContent']),
    setRightbarData(length = 0) {
      const rightBarContent = {
        header: {
          title: 'Settings',
        },
        profileInfo: [
          {
            isUploadButton: true,
            imageUrl: this.currentStudent?.profile?.image || null,
          },
        ],
        bargraph: [
          {
            barGraphTitle: this.$t('rightBar.Active Hours'),
            barGraphList: [
              { value: 2, color: '#267C26', tooltip: 'BIO' },
              { value: 12, color: '#267C26', tooltip: 'CHEM' },
              { value: 10, color: '#267C26', tooltip: 'PHY' },
              { value: 18, color: '#267C26', tooltip: 'MATH' },
              { value: 6, color: '#267C26', tooltip: 'ENG' },
              { value: 16, color: '#267C26', tooltip: 'URD' },
              { value: 4, color: '#267C26', tooltip: 'PAK' },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
  },
}
</script>
